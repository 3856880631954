import React, { Component } from "react";
import CardList from "./CardList.jsx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CardListVoltL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      itemList: [
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L no pipe 6st",
          url: "./models/Volt/Volt-model-L/volt_model_L_6st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_6st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L text 6st",
          url: "./models/Volt/Volt-model-L/volt_model_L_6st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_6st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L no pipe 8st",
          url: "./models/Volt/Volt-model-L/volt_model_L_8st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_8st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L text 8st",
          url: "./models/Volt/Volt-model-L/volt_model_L_8st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_8st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L no pipe 10st",
          url: "./models/Volt/Volt-model-L/volt_model_L_10st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_10st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L text 10st",
          url: "./models/Volt/Volt-model-L/volt_model_L_10st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_10st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L no pipe 12st",
          url: "./models/Volt/Volt-model-L/volt_model_L_12st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_12st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L text 12st",
          url: "./models/Volt/Volt-model-L/volt_model_L_12st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_12st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L no pipe 14st",
          url: "./models/Volt/Volt-model-L/volt_model_L_14st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_14st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L text 14st",
          url: "./models/Volt/Volt-model-L/volt_model_L_14st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_14st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L no pipe 16st",
          url: "./models/Volt/Volt-model-L/volt_model_L_16st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_16st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L text 16st",
          url: "./models/Volt/Volt-model-L/volt_model_L_16st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_16st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L no pipe 18st",
          url: "./models/Volt/Volt-model-L/volt_model_L_18st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_18st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L text 18st",
          url: "./models/Volt/Volt-model-L/volt_model_L_18st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_18st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L no pipe 20st",
          url: "./models/Volt/Volt-model-L/volt_model_L_20st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_20st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model L text 20st",
          url: "./models/Volt/Volt-model-L/volt_model_L_20st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_L_20st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
      ],
    };

    this.clearList = this.clearList.bind(this);
  }

  clearList() {
    if (this.state.isLoggedIn === false) {
      this.setState({
        itemList: [],
      });
    }
  }

  render() {
    return (
      <div>
        <CardList itemList={this.state.itemList} />
      </div>
    );
  }
}

export default CardListVoltL;
