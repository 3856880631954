import React, { Component } from "react";
import CardList from "./CardList.jsx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CardListThunder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      itemList: [
        {
          _id: "5f0de7b1e5148d0ef82ff08312",
          name: "Thunder 4st",
          url: "./models/thunder/thunder_4st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/thunder/thunder_4st.webp",
          category: "thunder",
          description: "https://anatol.com/presses/thunder/",
        },
        {
          _id: "5f1ec3d8030a8d1a29aedfaa23",
          name: "Thunder 6st",
          url: "./models/thunder/thunder_6st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/thunder/thunder_6st.webp",
          category: "thunder",
          description: "https://anatol.com/presses/thunder/",
        },
        {
          _id: "5f460a25b980fc045e08f70934",
          name: "Thunder 8st",
          url: "./models/thunder/thunder_8st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/thunder/thunder_8st.webp",
          category: "thunder",
          description: "https://anatol.com/presses/thunder/",
        },
        {
          _id: "5f460a25b980fc045e08f70945",
          name: "Thunder 10st",
          url: "./models/thunder/thunder_10st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/thunder/thunder_10st.webp",
          category: "thunder",
          description: "https://anatol.com/presses/thunder/",
        },
        {
          _id: "5f460a25b980fc045e08f70956",
          name: "Thunder 12st",
          url: "./models/thunder/thunder_12st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/thunder/thunder_12st.webp",
          category: "thunder",
          description: "https://anatol.com/presses/thunder/",
        },
      ],
    };

    this.clearList = this.clearList.bind(this);
  }

  clearList() {
    if (this.state.isLoggedIn === false) {
      this.setState({
        itemList: [],
      });
    }
  }

  render() {
    return (
      <div>
        <CardList itemList={this.state.itemList} />
      </div>
    );
  }
}

export default CardListThunder;
