import React, { Component } from "react";
import CardList from "./CardList.jsx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CardListLightning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      itemList: [
        {
          _id: "5f0de7b1e5148d0ef82ff08312",
          name: "Lightning 4st",
          url: "./models/lightning/lightning_4st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/lightning/lightning_4st.webp",
          category: "lightning",
          description: "https://anatol.com/presses/lightning/",
        },
        {
          _id: "5f1ec3d8030a8d1a29aedfaa23",
          name: "Lightning 6st",
          url: "./models/lightning/lightning_6st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/lightning/lightning_6st.webp",
          category: "lightning",
          description: "https://anatol.com/presses/lightning/",
        },
        {
          _id: "5f460a25b980fc045e08f70934",
          name: "Lightning 8st",
          url: "./models/lightning/lightning_8st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/lightning/lightning_8st.webp",
          category: "lightning",
          description: "https://anatol.com/presses/lightning/",
        },
      ],
    };

    this.clearList = this.clearList.bind(this);
  }

  clearList() {
    if (this.state.isLoggedIn === false) {
      this.setState({
        itemList: [],
      });
    }
  }

  render() {
    return (
      <div>
        <CardList itemList={this.state.itemList} />
      </div>
    );
  }
}

export default CardListLightning;
