import "../../../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import ModalHeading from "../../ModalHeading.jsx";
import SaveFileList from "../SaveFileList.jsx";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {
  BASE_URL,
  FLOOR_MODELS,
  REMOVE_MODEL,
} from "../../../Constants";
import LargeAlert from "../../LargeAlert.jsx";
import $ from "jquery";
import SubmitForm from "../../SubmitForm";

@inject("store")
@observer
class SaveModal extends Component {
  constructor(props) {
    super(props);
    this.sendMail = this.sendMail.bind(this);
    this.state = {
      isDisabled: false,
      isError: false,
      fileName: "",
      fileNameTemp: "",
      fileUri: "",
      stateBlob: {},
      saveFileList: [],
      showModal: false,
      copied: false,
      overwrite: "Save",
    };

    this.handleClose = this.handleClose.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.updateSaveFileList = this.updateSaveFileList.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleShare = this.handleShare.bind(this);
  }


  async sendMail(event) {
    event.preventDefault();
    const form = document.getElementById("__vtigerWebForm");
    const url = "https://testvtsale.anatol.com/modules/Webforms/capture.php";
    const nameInput = document.getElementById('name');
    const lastnameInput = document.getElementById('lastname');
    const emailInput = document.getElementById('email');
    const phoneInput = document.getElementById('phone');
    const fileInput = document.getElementById('file');
    const submitBtn = document.querySelector('#submitBtn');
    function checkInputs() {
      const nameValue = nameInput.value.trim();
      const emailValue = emailInput.value.trim();

      if (nameValue === "" || emailValue === "") {
        submitBtn.disabled = true;
      } else {
        submitBtn.disabled = false;
      }
    }

    nameInput.addEventListener("input", checkInputs);
    emailInput.addEventListener("input", checkInputs);
    lastnameInput.addEventListener("input", checkInputs);
    phoneInput.addEventListener("input", checkInputs);
    fileInput.addEventListener("input", checkInputs);


    const formData = new FormData(form);
    const requestOptions = {
      method: "POST",
      mode: "no-cors",
      body: formData,
    };

    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        console.log("Message sent successfully!");
        form.reset();
      } else {
        console.log("send message.");
      }
    } catch (error) {
      // console.log("An error occurred while sending the message.");
      console.error(error);
    }
  }

  handleClose() {
    this.props.store.setSaveFileModal(false);
    this.setState({
      fileName: "",
      fileUri: "",
      showModal: false,
      isError: false,
    });
  }

  handleShare() {
    this.setState({ copied: true, isError: false });
  }

  async handleRemove(id) {
    let token = this.props.store.getAccessToken;
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let body = { _id: id };
    try {
      const response = await axios.post(
        BASE_URL + FLOOR_MODELS + REMOVE_MODEL,
        body,
        config
      );
      const blob = new Blob([response.data], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "filename.extension");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.updateSaveFileList();
    } catch (e) {
      console.log(e);
      this.setState({ isError: true });
    }
  }


  handleClick(name, uri, event) {
    this.setState({
      fileName: name,
      fileUri: uri,
      isError: false,
      overwrite: "Overwrite",
    });
    console.log(name)
    $(".save-file-list-item").css("border-color", "#ffffff");
    event.currentTarget.style.borderColor = "#5a6268";

  }

  handleInputChange(event) {
    $(".save-file-list-item").css("border-color", "#ffffff");
    this.setState({
      fileName: event.target.value,
      fileNameTemp: event.target.value,
      isError: false,
      overwrite: "Save",
    });
  }

  async updateSaveFileList() {

  }

  async uploadFile(blob) {
    if (!this.state.isDisabled && this.state.fileName !== "") {
      const data = new FormData();
      data.append("file", blob);
    } else {
      this.setState({ isDisabled: false, isError: false });
    }
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (
      prevState.stateBlob !== this.props.blob &&
      this.props.blob !== null &&
      this.props.blob !== "" &&
      this.state.isDisabled === false
    ) {
      this.setState({
        stateBlob: this.props.blob,
        isDisabled: true,
        isError: false,
        overwrite: "Save",
      });
      this.uploadFile(this.props.blob);
    }

    if (this.props.store.showSaveFileModal && !prevState.showModal) {
      this.setState({ showModal: true, isError: false, overwrite: "Save" });
      this.updateSaveFileList();
    }
  }

  render() {
    const { store } = this.props;
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={store.showSaveFileModal}
        onHide={() => this.handleClose()}
        id="saveModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <ModalHeading icon="saveFile" message="Save Project" />
          </Modal.Title>

        </Modal.Header>

        <Modal.Body>
          <div className="description">
            <span>1. Save floorplan file to your local machine by pressing "Save" button.</span>
            <span>2. Fill form and attach saved file to it.</span>
            <span>3. Send floorplan and contact information to "Anatol".</span>
          </div>
          <Button
              variant="danger"
              className="login-submit-button"
              disabled={this.state.isDisabled}
              onClick={this.props.clickFunc}
          >
            {this.state.overwrite}
          </Button>
          <div className="login-form-space">

            <form
                onSubmit={this.sendMail}
                id="__vtigerWebForm"
                name="Get a quote on the equipment page"
                method="post"
                acceptCharset="utf-8"
                encType="multipart/form-data"
                action="https://testvtsale.anatol.com/modules/Webforms/capture.php"
            >
              <input type="hidden" name="__vtrftk" defaultValue="sid:95b4b634122ce6620d5979843bf96ef85284ec54,1678956117" />
              <input type="hidden" name="publicid" defaultValue="f97ca70e8001248f1c860fad5b1ce65a" />
              <input type="hidden" name="urlencodeenable" defaultValue="1" />
              <input type="hidden" name="name" defaultValue="Get a quote on the equipment page" />

              <table>
                <tbody>
                <tr>
                  <td><label>First Name*</label></td>
                  <td><input id="name" type="text" name="firstname" data-label="" defaultValue="" required /></td>
                </tr>
                <tr>
                  <td><label>Last Name*</label></td>
                  <td><input id="lastname" type="text" name="lastname" data-label="" defaultValue="" required /></td>
                </tr>
                <tr>
                  <td><label>Phone*</label></td>
                  <td><input id="phone" type="text" name="phone" data-label="" defaultValue="" required /></td>
                </tr>
                <tr>
                  <td><label>Email*</label></td>
                  <td><input id="email" type="email" name="email" data-label="" defaultValue="" required /></td>
                </tr>
                <tr>
                  <td><label>File*</label></td>
                  <td><input id="file" type="file" name="file_3_1" required /></td>
                </tr>
                </tbody>
              </table>
              <Button
                  id="submitBtn"
                  type="submit"
                  variant="danger"
                  className="login-submit-button submit-form"
              >
                Send Layout
              </Button>
            </form>

            <SaveFileList type="submit"
              clickFunc={this.handleClick}
              removeFunc={this.handleRemove}
              shareFunc={this.handleShare}
              fileList={this.state.saveFileList}
            />

            {this.state.copied && (
              <LargeAlert
                variant="success"
                message="Link Copied to Clipboard"
              />
            )}

          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SaveModal;
