import "../../../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import ModalHeading from "../../ModalHeading.jsx";
import Button from "react-bootstrap/Button";
import LargeAlert from "../../LargeAlert.jsx";
import { INIT_STRUCTURE } from "../../../Structures";

@inject("store")
@observer
class LoadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      isError: false,
      file: null,
      saveFileList: [],
      showModal: false,
      copied: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.updateSaveFileList = this.updateSaveFileList.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleShare = this.handleShare.bind(this);
  }

  async handleButtonClick() {
    try {
      let strukture = null;
      let fileContents = null;
      if (this.state.file !== null) {
        const file = this.state.file;
        const reader = new FileReader();
        reader.readAsText(file);
        fileContents = await new Promise((resolve) => {
          reader.onload = (event) => {
            resolve(event.target.result);
          };
        });
      }
      if (fileContents) {
        const blueprint3d = JSON.parse(fileContents);
        console.log(fileContents)
        if (blueprint3d && blueprint3d.floorplan) {
          if (this.props.store && this.props.store.blueprint3d && this.props.store.blueprint3d.model) {
            this.props.store.blueprint3d.model.loadSerialized(
              JSON.stringify(blueprint3d.floorplan)
            );
          }
          strukture = blueprint3d.floorplan; // set the uploaded file as the new structure
        }
        localStorage.setItem('fileContents', fileContents); // set to localStorage
      } else {
        if (this.props.store && this.props.store.blueprint3d && this.props.store.blueprint3d.model) {
          this.props.store.blueprint3d.model.loadSerialized(
            JSON.stringify(INIT_STRUCTURE)
          );
        }
        strukture = INIT_STRUCTURE; // set the default structure if no file is uploaded
        localStorage.setItem('fileContents', JSON.stringify(INIT_STRUCTURE)); // set default to localStorage
      }
      // replace INIT_STRUCTURE with the new or default structure
      if (this.props.store && this.props.store.blueprint3d && this.props.store.blueprint3d.model) {
        this.props.store.blueprint3d.model.loadSerialized(
          JSON.stringify(strukture)
        );
      }
      this.handleClose();
      window.location.reload();
    } catch (error) {
      console.log(error);
      if (this.props.store && this.props.store.blueprint3d && this.props.store.blueprint3d.model) {
        this.props.store.blueprint3d.model.loadSerialized(
          JSON.stringify(INIT_STRUCTURE)
        );
      }
      localStorage.setItem('fileContents', JSON.stringify(INIT_STRUCTURE)); // set default to localStorage
      this.handleClose();
    }

  }


  handleClose() {
    this.props.store.setLoadFileModal(false);
    this.setState({
      file: null,
      showModal: false,
      isError: false,
    });
  }

  handleShare() {
    this.setState({ copied: true, isError: false });
  }

  async handleRemove(id) {
    // TODO: Implement remove functionality if necessary
  }

  handleFileChange(event) {
    this.setState({
      file: event.target.files[0],
    });
  }

  async updateSaveFileList() {
    // TODO: Implement update functionality if necessary
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (this.props.store.showLoadFileModal && !prevState.showModal) {
      this.setState({ showModal: true, isError: false });
    }
  }

  render() {
    const { store } = this.props;
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={store.showLoadFileModal}
        onHide={() => this.handleClose()}
        id="loadModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <ModalHeading icon="loadFile" message="Load Project" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="login-form-space">
            <input type="file" id="qqq" onChange={this.handleFileChange} />
            <Button
              variant="danger"
              className="login-submit-button"
              disabled={this.state.isDisabled}
              onClick={this.handleButtonClick}
            >
              Load
            </Button>

            {this.state.copied && (
              <LargeAlert
                variant="success"
                message="Link Copied to Clipboard"
              />
            )}
            {this.state.isError && (
              <LargeAlert variant="danger" message="Some Error Occurred" />
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}


export default LoadModal;
