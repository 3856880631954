import React, { Component } from "react";
import CardList from "./CardList.jsx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CardListProdigy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      itemList: [
        {
          _id: "5f0de7b1e5148d0ef82ff0831",
          name: "Prodigy 8x8 6st",
          url: "./models/prodigy/Prodigy_8x8_6st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/prodigy/prodigy_8x8_6st.webp",
          category: "prodigy",
          description: "https://anatol.com/presses/prodigy/",
        },
        {
          _id: "5f1ec3d8030a8d1a29aedfaa2",
          name: "Prodigy 8x8 8st",
          url: "./models/prodigy/Prodigy_8x8_8st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/prodigy/prodigy_8x8_8st.webp",
          category: "prodigy",
          description: "https://anatol.com/presses/prodigy/",
        },
        {
          _id: "5f460a25b980fc045e08f7093",
          name: "Prodigy 8x8 10st",
          url: "./models/prodigy/Prodigy_8x8_10st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/prodigy/prodigy_8x8_10st.webp",
          category: "prodigy",
          description: "https://anatol.com/presses/prodigy/",
        },
        {
          _id: "5f460a25b980fc045e08f7094",
          name: "Prodigy 10x12 6st",
          url: "./models/prodigy/Prodigy_10x12_6st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/prodigy/prodigy_10x12_6st.webp",
          category: "prodigy",
          description: "https://anatol.com/presses/prodigy/",
        },
        {
          _id: "5f460a25b980fc045e08f7095",
          name: "Prodigy 10x12 8st",
          url: "./models/prodigy/Prodigy_10x12_8st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/prodigy/prodigy_10x12_8st.webp",
          category: "prodigy",
          description: "https://anatol.com/presses/prodigy/",
        },
        {
          _id: "5f460a25b980fc045e08f7096",
          name: "Prodigy 10x12 10st",
          url: "./models/prodigy/Prodigy_10x12_10st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/prodigy/prodigy_10x12_10st.webp",
          category: "prodigy",
          description: "https://anatol.com/presses/prodigy/",
        },
      ],
    };

    this.clearList = this.clearList.bind(this);
  }

  clearList() {
    if (this.state.isLoggedIn === false) {
      this.setState({
        itemList: [],
      });
    }
  }

  render() {
    return (
      <div>
        <CardList itemList={this.state.itemList} />
      </div>
    );
  }
}

export default CardListProdigy;
