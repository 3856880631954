import React, { Component } from "react";
import CardList from "./CardList.jsx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CardListDryer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      itemList: [
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Aurora",
          url: "./models/prepress/aurora.glb",
          type: "1",
          imgUrl: "./assets/thumbs/prepress/aurora.webp",
          category: "prepress",
          description:
            "https://anatol.com/pre-press/aurora-uv-led-exposure-unit/",
        },
        {
          _id: "5f665431e5148d03f82ff0831",
          name: "Cube",
          url: "./models/prepress/cube.glb",
          type: "1",
          imgUrl: "../assets/thumbs/prepress/cube.webp",
          category: "prepress",
          description:
            "https://anatol.com/pre-press/cube-screen-drying-cabinet/",
        },
        {
          _id: "5f665431e5148d03f82ff0831",
          name: "Quick-kote",
          url: "./models/prepress/quick-kote.glb",
          type: "1",
          imgUrl: "../assets/thumbs/prepress/quick-kote.webp",
          category: "prepress",
          description: "https://anatol.com/pre-press/quik-kote-mini/",
        },
        {
          _id: "5f665431e5148d03f82ff0831",
          name: "Washout booth",
          url: "./models/prepress/Screen-Printing-Washout-Booth.glb",
          type: "1",
          imgUrl:
            "../assets/thumbs/prepress/screen-printing-washout-booth.webp",
          category: "prepress",
          description:
            "https://anatol.com/pre-press/screen-printing-washout-booth/",
        },
      ],
    };

    this.clearList = this.clearList.bind(this);
  }

  clearList() {
    if (this.state.isLoggedIn === false) {
      this.setState({
        itemList: [],
      });
    }
  }

  render() {
    return (
      <div>
        <CardList itemList={this.state.itemList} />
      </div>
    );
  }
}

export default CardListDryer;
