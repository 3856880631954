import React, {Component} from "react";
import TextureList from "./TextureList.jsx";
import {inject, observer} from "mobx-react";

@inject("store")
@observer
class WallTextureList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            textureList: [
                {
                    "_id": "01",
                    "name": "Bricks",
                    "url": "./textures/walls/brick.jpeg",
                    "stretch": false,
                    "scale": 100,
                    "thumbnailUrl": "./textures/walls/brick.jpeg",
                },
                {
                    "_id": "013",
                    "name": "Bricks 2",
                    "url": "./textures/walls/brick_2.jpg",
                    "stretch": false,
                    "scale": 100,
                    "thumbnailUrl": "./textures/walls/brick_2.jpg",
                },
            ],
            textureListSolid: [
                {
                    "_id": "02",
                    "name": "Blue",
                    "url": "./textures/walls/blue.webp",
                    "stretch": false,
                    "scale": 100,
                    "thumbnailUrl": "./textures/walls/blue.webp",
                },
                {
                    "_id": "03",
                    "name": "Dark Blue",
                    "url": "./textures/walls/grey-wall.webp",
                    "stretch": false,
                    "scale": 100,
                    "thumbnailUrl": "./textures/walls/grey-wall.webp",
                },
            ],
            textureListTile: [
                {
                    "_id": "04",
                    "name": "Stone",
                    "url": "./textures/walls/stone.jpg",
                    "stretch": false,
                    "scale": 300,
                    "thumbnailUrl": "./textures/walls/stone.jpg",
                },
                {
                    "_id": "0212",
                    "name": "Red Bricks",
                    "url": "./textures/walls/red-briks.webp",
                    "stretch": false,
                    "scale": 100,
                    "thumbnailUrl": "./textures/walls/red-briks.webp",
                },

            ],
        };

        this.clearList = this.clearList.bind(this);
    }

    clearList() {
        if (this.state.isLoggedIn === false) {
            this.setState({
                textureList: [],
                textureListSolid: [],
                textureListTile: [],
            });
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        if (this.props.store.getLoggedIn && prevState.isLoggedIn === false) {
            this.setState({isLoggedIn: true});
            this.clearList();
        }
        if (this.props.store.getLoggedIn === false && prevState.isLoggedIn) {
            this.setState({isLoggedIn: false});
            this.clearList();
        }
    }

    render() {
        return (
            <div className="texture-panel">
                <div className="panel-heading">Adjust Wall</div>
                <hr className="small-underline"/>
                <div className="texture-panel-heading">Patterns</div>
                <TextureList textureList={this.state.textureList.reverse()}/>
                <div className="texture-panel-heading">Solids</div>
                <TextureList textureList={this.state.textureListSolid.reverse()}/>
                <div className="texture-panel-heading">Tiles</div>
                <TextureList textureList={this.state.textureListTile.reverse()}/>
            </div>
        );
    }
}

export default WallTextureList;
