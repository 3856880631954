import React, { Component } from "react";
import CardList from "./CardList.jsx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CardListDryer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      itemList: [
        {
          _id: "5f0de7b1e5148d03f82ff0831",
          name: "Solution no pipe",
          url: "./models/dryers/solution_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/dryers/solution_no_pipe.webp",
          category: "dryer",
          description:
            "https://anatol.com/conveyor-dryers/solutions-electric-conveyor-dryer/",
        },
        {
          _id: "5f6657b1e5148d03f82ff0831",
          name: "Solution",
          url: "./models/dryers/solution.glb",
          type: "1",
          imgUrl: "./assets/thumbs/dryers/solution.webp",
          category: "dryer",
          description:
            "https://anatol.com/conveyor-dryers/solutions-electric-conveyor-dryer/",
        },
        {
          _id: "5f6657b1e5148d03f82ff0831",
          name: "Ultra mon cab left no pipe",
          url: "./models/dryers/ultra_mon_cab_left_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/dryers/ultra_mon_cab_left_no_pipe.webp",
          category: "dryer",
          description:
            "https://anatol.com/conveyor-dryers/ultra-electric-dryer/",
        },
        {
          _id: "5f6657b1e5148d03f82ff0831",
          name: "Ultra mon cab left",
          url: "./models/dryers/ultra_mon_cab_left_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/dryers/ultra_mon_cab_left_no_pipe.webp",
          category: "dryer",
          description:
            "https://anatol.com/conveyor-dryers/ultra-electric-dryer/",
        },
        {
          _id: "5f6657b1e5148d03f82ff0831",
          name: "Ultra mon cab right no pipe",
          url: "./models/dryers/ultra_mon_cab_right_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/dryers/ultra_mon_cab_left_no_pipe.webp",
          category: "dryer",
          description:
            "https://anatol.com/conveyor-dryers/ultra-electric-dryer/",
        },
        {
          _id: "5f6657b1e5148d03f82ff0831",
          name: "Ultra mon cab right",
          url: "./models/dryers/ultra_mon_cab_right_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/dryers/ultra_mon_cab_left_no_pipe.webp",
          category: "dryer",
          description:
            "https://anatol.com/conveyor-dryers/ultra-electric-dryer/",
        },
        {
          _id: "5f6657b1e5148d03f82ff0831",
          name: "Vulcan",
          url: "./models/dryers/vulcan.glb",
          type: "1",
          imgUrl: "./assets/thumbs/dryers/vulcan.webp",
          category: "dryer",
          description: "https://anatol.com/conveyor-dryers/vulcan/",
        },
        {
          _id: "5f6657b1e5148d03f82ff0831",
          name: "Vulcan no pipe",
          url: "./models/dryers/vulcan_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/dryers/vulcan_no_pipe.webp",
          category: "dryer",
          description: "https://anatol.com/conveyor-dryers/vulcan/",
        },
      ],
    };

    this.clearList = this.clearList.bind(this);
  }

  clearList() {
    if (this.state.isLoggedIn === false) {
      this.setState({
        itemList: [],
      });
    }
  }

  render() {
    return (
      <div>
        <CardList itemList={this.state.itemList} />
      </div>
    );
  }
}

export default CardListDryer;
