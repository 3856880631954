import React, { Component } from "react";
import CardList from "./CardList.jsx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CardListTitan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      itemList: [
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Titan S AAA 6st",
          url: "./models/titan/titan-s/titan_S_AAA_6st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/titan/titan_S_AAA_6st.webp",
          category: "titan",
          description: "https://anatol.com/presses/titan-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Titan S AAA 8st",
          url: "./models/titan/titan-s/titan_S_AAA_8st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/titan/titan_S_AAA_8st.webp",
          category: "titan",
          description: "https://anatol.com/presses/titan-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Titan S ASA 6st",
          url: "./models/titan/titan-s/titan_S_ASA_6st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/titan/titan_S_ASA_6st.webp",
          category: "titan",
          description: "https://anatol.com/presses/titan-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Titan S ASA 8st",
          url: "./models/titan/titan-s/titan_S_ASA_8st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/titan/titan_S_ASA_8st.webp",
          category: "titan",
          description: "https://anatol.com/presses/titan-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Titan S ASA 10st",
          url: "./models/titan/titan-s/titan_S_ASA_10st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/titan/titan_S_ASA_10st.webp",
          category: "titan",
          description: "https://anatol.com/presses/titan-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Titan S ASA 12st",
          url: "./models/titan/titan-s/titan_S_ASA_12st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/titan/titan_S_ASA_12st.webp",
          category: "titan",
          description: "https://anatol.com/presses/titan-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Titan S ASE 6st",
          url: "./models/titan/titan-s/titan_S_ASE_6st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/titan/titan_S_ASE_6st.webp",
          category: "titan",
          description: "https://anatol.com/presses/titan-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Titan S ASE 8st",
          url: "./models/titan/titan-s/titan_S_ASE_8st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/titan/titan_S_ASE_8st.webp",
          category: "titan",
          description: "https://anatol.com/presses/titan-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Titan S ASE 10st",
          url: "./models/titan/titan-s/titan_S_ASE_10st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/titan/titan_S_ASE_10st.webp",
          category: "titan",
          description: "https://anatol.com/presses/titan-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Titan S ASE 12st",
          url: "./models/titan/titan-s/titan_S_ASE_12st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/titan/titan_S_ASE_12st.webp",
          category: "titan",
          description: "https://anatol.com/presses/titan-machine/",
        },
      ],
    };

    this.clearList = this.clearList.bind(this);
  }

  clearList() {
    if (this.state.isLoggedIn === false) {
      this.setState({
        itemList: [],
      });
    }
  }

  render() {
    return (
      <div>
        <CardList itemList={this.state.itemList} />
      </div>
    );
  }
}

export default CardListTitan;
