// DEV-LOCAL
export const HOST_URL = "https://3dplan.anatol.com/";
export const BASE_URL = "http://localhost:3000/";
export const BASE_URL_AUTH = "http://localhost:8000/";
export const DEFAULT_WALL_MAP = "";
export const DEFAULT_FLOOR_MAP = "";

// COMMON-----------------------------------------------------------
export const ASSETS = "assets/";
export const MODELS = "models/";
export const FLOOR_MODELS = "floorModel/"
export const TEXTURES = "textures/";
export const RETRIEVE = "retrieve/";
export const USERS = "users/";
export const RESOURCES = "resources/";
export const FIND = "find/";
export const VIEW_MODEL = "view/"

export const REGISTER = "register";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const UPLOAD_ASSET = "uploadAsset"
export const UPDATE_ASSET_ENTRY = "updateAssetEntry"

export const PRODIGY_CATEGORY = "prodigy";
export const THUNDER_CATEGORY = "thuder";
export const LIGHTNING_CATEGORY = "lightning";
export const DRYER_CATEGORY = "dryer";
export const PREPRESS_CATEGORY = "repress";
export const TITAN_CATEGORY = "titan";
export const VECTOR_CATEGORY = "vector";
export const VOLT_CATEGORY = "volt";
export const OTHER_CATEGORY = "other";
export const FLOOR_CATEGORY = "floor";
export const FLOOR_WOOD_CATEGORY = "floorMarble";
export const FLOOR_MARBLE_CATEGORY = "floorMarble";
export const FLOOR_TILE_CATEGORY = "floorTile";
export const WALL_CATEGORY = "wall";
export const WALL_SOLID_CATEGORY = "solid";
export const WALL_TILE_CATEGORY = "wallTile";
export const GET_FREE_RESOURCES = "getFreeResources";
export const GET_RESOURCES = "getResources"

export const REMOVE_MODEL = "removeModel"
export const RETRIEVE_MODEL = "retrieve"
export const UPDATE_MODEL = "update"