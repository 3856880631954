import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./LuminescenceStore";
import html2canvas from "html2canvas";
import {Provider} from "mobx-react";
import * as jspdf from "jspdf";

function Root() {
    const printPDF = () => {
        var element = document.getElementById('root');

        html2canvas(element).then((canvas) => {
            console.log(canvas)
            var imgData = canvas.toDataURL('image/png');
            var doc = new jspdf.jsPDF('landscape');
            var imgHeight = canvas.height * 300 / canvas.width;
            doc.addImage(imgData, 0, 20, 300, imgHeight);
            doc.save("result.pdf");
        });
    };
    return (
        <div>
            <Provider store={store}>
                <button id="print" className="basic-button btn btn-danger btn-sm" onClick={printPDF}>
                    Save to PDF
                </button>
                <App/>
            </Provider>
        </div>
    );
}

serviceWorker.unregister();
const rootElement = document.getElementById("root");
ReactDOM.render(<Root/>, rootElement);
