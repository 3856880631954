import React, { Component } from "react";
import CardList from "./CardList.jsx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CardListOther extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            itemList: [
                {
                    "_id": "5f0",
                    "name": "Man",
                    "url": "./models/other/men.glb",
                    "type": "1",
                    "imgUrl": "./assets/thumbs/other/man.webp",
                    "category": "other"
                },
                {
                    "_id": "5c0",
                    "name": "Doors",
                    "url": "./models/other/doors.glb",
                    "type": "7",
                    "imgUrl": "./assets/thumbs/other/doors.webp",
                    "category": "other",
                    "fixed": false
                },
                {
                    "_id": "5g0",
                    "name": "Doorway",
                    "url": "./models/other/doorway.glb",
                    "type": "7",
                    "imgUrl": "./assets/thumbs/other/doorway.webp",
                    "category": "other",
                    "fixed": false
                },
                {
                    "_id": "5g0ww",
                    "name": "Window",
                    "type": 3,
                    "url": "./models/other/window.glb",
                    "imgUrl": "./assets/thumbs/other/window.webp",
                    "category": "other",
                    "fixed": false
                },
                {
                    "_id": "5g0ww",
                    "name": "Cabinet",
                    "type": 1,
                    "url": "./models/other/cabinet.glb",
                    "imgUrl": "./assets/thumbs/other/cabinet.webp",
                    "category": "other",
                    "fixed": false
                },
                {
                    "_id": "5g0ww",
                    "name": "Chair",
                    "type": 1,
                    "url": "./models/other/chair.glb",
                    "imgUrl": "./assets/thumbs/other/chair.webp",
                    "category": "other",
                    "fixed": false
                },
                {
                    "_id": "5g044ww",
                    "name": "Table",
                    "type": 1,
                    "url": "./models/other/table.glb",
                    "imgUrl": "./assets/thumbs/other/table.webp",
                    "category": "other",
                    "fixed": false,
                    "rotation": 0,
                    "scale_x": 1,
                    "scale_y": 1,
                    "scale_z": 1,
                },
                {
                    "_id": "5g04ee4ww",
                    "name": "Pillar",
                    "type": 1,
                    "url": "./models/other/pillar.glb",
                    "imgUrl": "./assets/thumbs/other/pillar.webp",
                    "category": "other",
                    "fixed": false,
                    "rotation": 0,
                    "scale_x": 1,
                    "scale_y": 1,
                    "scale_z": 1,
                },
                {
                    "_id": "5g044ww",
                    "name": "Cube",
                    "type": 1,
                    "url": "./models/other/box.glb",
                    "imgUrl": "./assets/thumbs/other/cube.webp",
                    "category": "other",
                    "fixed": false,
                    "rotation": 0,
                    "scale_x": 1,
                    "scale_y": 1,
                    "scale_z": 1,
                }
            ],
        };

        this.clearList = this.clearList.bind(this);
    }

    clearList() {
        if (this.state.isLoggedIn === false) {
            this.setState({
                itemList: [],
            });
        }
    }

    render() {
        return (
            <div>
                <CardList itemList={this.state.itemList} />
            </div>
        );
    }
}

export default CardListOther;
