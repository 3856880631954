import React, { Component } from "react";
import CardList from "./CardList.jsx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CardListVectorL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      itemList: [
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Vector model L 6st",
          url: "./models/Vector/Vector-model-L/vector_model_L_6st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/vector/vector_model_L_6st.webp",
          category: "vector",
          description: "https://anatol.com/presses/vector/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Vector model L 8st",
          url: "./models/Vector/Vector-model-L/vector_model_L_8st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/vector/vector_model_L_8st.webp",
          category: "vector",
          description: "https://anatol.com/presses/vector/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Vector model L 10st",
          url: "./models/Vector/Vector-model-L/vector_model_L_10st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/vector/vector_model_L_10st.webp",
          category: "vector",
          description: "https://anatol.com/presses/vector/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Vector model L 12st",
          url: "./models/Vector/Vector-model-L/vector_model_L_12st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/vector/vector_model_L_12st.webp",
          category: "vector",
          description: "https://anatol.com/presses/vector/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Vector model L 14st",
          url: "./models/Vector/Vector-model-L/vector_model_L_14st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/vector/vector_model_L_14st.webp",
          category: "vector",
          description: "https://anatol.com/presses/vector/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Vector model L 16st",
          url: "./models/Vector/Vector-model-L/vector_model_L_16st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/vector/vector_model_L_16st.webp",
          category: "vector",
          description: "https://anatol.com/presses/vector/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Vector model L 18st",
          url: "./models/Vector/Vector-model-L/vector_model_L_18st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/vector/vector_model_L_18st.webp",
          category: "vector",
          description: "https://anatol.com/presses/vector/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Vector model L 20st",
          url: "./models/Vector/Vector-model-L/vector_model_L_20st.glb",
          type: "1",
          imgUrl: "./assets/thumbs/vector/vector_model_L_20st.webp",
          category: "vector",
          description: "https://anatol.com/presses/vector/",
        },
      ],
    };

    this.clearList = this.clearList.bind(this);
  }

  clearList() {
    if (this.state.isLoggedIn === false) {
      this.setState({
        itemList: [],
      });
    }
  }

  render() {
    return (
      <div>
        <CardList itemList={this.state.itemList} />
      </div>
    );
  }
}

export default CardListVectorL;
