import React, {Component} from "react";
import TextureList from "./TextureList.jsx";
import {inject, observer} from "mobx-react";

@inject("store")
@observer
class FloorTextureList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            textureListWood: [
                {
                    "_id": "04",
                    "name": "Light floor",
                    "url": "./textures/floors/stone_tiles.jpg",
                    "stretch": false,
                    "scale": 100,
                    "thumbnailUrl": "./textures/floors/stone_tiles.jpg",
                },                {
                    "_id": "041",
                    "name": "Dark Wood",
                    "url": "./textures/floors/dark_wood.jpeg",
                    "stretch": false,
                    "scale": 200,
                    "thumbnailUrl": "./textures/floors/dark_wood.jpeg",
                },
            ],
            textureListMarble: [
                {
                    "_id": "02",
                    "name": "Stone floor",
                    "url": "./textures/floors/stone_floor.jpg",
                    "stretch": false,
                    "scale": 300,
                    "thumbnailUrl": "./textures/floors/stone_floor.jpg",
                },
                {
                    "_id": "022",
                    "name": "Stone briks",
                    "url": "./textures/floors/bluefloor.webp",
                    "stretch": false,
                    "scale": 300,
                    "thumbnailUrl": "./textures/floors/bluefloor.webp",
                }
            ],
            textureListTile: [
                {
                    "_id": "01",
                    "name": "Dark floor",
                    "url": "./textures/floors/dark-floor.png",
                    "stretch": false,
                    "scale": 100,
                    "thumbnailUrl": "./textures/floors/dark-floor.png",
                },
                {
                    "_id": "03",
                    "name": "Light floor",
                    "url": "./textures/floors/floor.jpeg",
                    "stretch": false,
                    "scale": 300,
                    "thumbnailUrl": "./textures/floors/floor.jpeg",
                }

            ],
        };

        this.clearList = this.clearList.bind(this);
    }

    clearList() {
        if (this.state.isLoggedIn === false) {
            this.setState({
                textureListWood: [],
                textureListMarble: [],
                textureListTile: [],
            });
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        if (this.props.store.getLoggedIn && prevState.isLoggedIn === false) {
            this.setState({isLoggedIn: true});
            this.clearList();
            this.getUserList();
        }
        if (this.props.store.getLoggedIn === false && prevState.isLoggedIn) {
            this.setState({isLoggedIn: false});
            this.clearList();
            this.getFreeList();
        }
    }

    render() {
        return (
            <div className="texture-panel">
                <div className="panel-heading">Adjust Floor</div>
                <hr className="small-underline"/>
                <div className="texture-panel-heading">Wood</div>
                <TextureList textureList={this.state.textureListWood.reverse()}/>
                <div className="texture-panel-heading">Stone</div>
                <TextureList textureList={this.state.textureListMarble.reverse()}/>
                <div className="texture-panel-heading">Tiles</div>
                <TextureList textureList={this.state.textureListTile.reverse()}/>
            </div>
        );
    }
}

export default FloorTextureList;
