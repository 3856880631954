import React, { Component } from "react";
import CardList from "./CardList.jsx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CardListVoltL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      itemList: [
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model S 6st no pipe",
          url: "./models/Volt/Volt-model-S/volt_model_S_6st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_S_6st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model S 6st text",
          url: "./models/Volt/Volt-model-S/volt_model_S_6st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_S_6st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model S 8st no pipe",
          url: "./models/Volt/Volt-model-S/volt_model_S_8st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_S_8st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model S 8st text",
          url: "./models/Volt/Volt-model-S/volt_model_S_8st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_S_8st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model S 10st no pipe",
          url: "./models/Volt/Volt-model-S/volt_model_S_10st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_S_10st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model S 10st text",
          url: "./models/Volt/Volt-model-S/volt_model_S_10st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_S_10st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model S 12st no pipe",
          url: "./models/Volt/Volt-model-S/volt_model_S_12st_no_pipe.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_S_12st_no_pipe.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
        {
          _id: "5f0de7b165148d03f82ff0831",
          name: "Volt model S 12st text",
          url: "./models/Volt/Volt-model-S/volt_model_S_12st_text.glb",
          type: "1",
          imgUrl: "./assets/thumbs/Volt/volt_model_S_12st_text.webp",
          category: "volt",
          description:
            "https://anatol.com/presses/volt-automatic-screen-printing-machine/",
        },
      ],
    };

    this.clearList = this.clearList.bind(this);
  }

  clearList() {
    if (this.state.isLoggedIn === false) {
      this.setState({
        itemList: [],
      });
    }
  }

  render() {
    return (
      <div>
        <CardList itemList={this.state.itemList} />
      </div>
    );
  }
}

export default CardListVoltL;
