import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Button from "react-bootstrap/Button";
import "../../src/Form.css";

@inject("store")
@observer
class SubmitForm extends Component {
  constructor(props) {
    super(props);
    this.sendMail = this.sendMail.bind(this);
  }

  async sendMail(event) {
    event.preventDefault();
    const form = document.getElementById("__vtigerWebForm");
    const url = "https://testvtsale.anatol.com/modules/Webforms/capture.php";
    const formData = new FormData(form);
    const requestOptions = {
      method: "POST",
      mode: "no-cors",
      body: formData,
    };

    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        console.log("Message sent successfully!");
        form.reset();
      } else {
        console.log("send message.");
      }
    } catch (error) {
        console.log("An error occurred while sending the message.");
      console.error(error);
    }
  }

  render() {
    const { store } = this.props;
    return (
      <form
        onSubmit={this.sendMail}
        id="__vtigerWebForm"
        name="Get a quote on the equipment page"
        method="post"
        acceptCharset="utf-8"
        encType="multipart/form-data"
        action="https://testvtsale.anatol.com/modules/Webforms/capture.php"
      >
                <input type="hidden" name="__vtrftk" defaultValue="sid:95b4b634122ce6620d5979843bf96ef85284ec54,1678956117" />
                <input type="hidden" name="publicid" defaultValue="f97ca70e8001248f1c860fad5b1ce65a" />
                <input type="hidden" name="urlencodeenable" defaultValue="1" />
                <input type="hidden" name="name" defaultValue="Get a quote on the equipment page" />

                <table>
                    <tbody>
                        <tr>
                            <td><label>First Name*</label></td>
                            <td><input type="text" name="firstname" data-label="" defaultValue="" required="" /></td>
                        </tr>
                        <tr>
                            <td><label>Last Name*</label></td>
                            <td><input type="text" name="lastname" data-label="" defaultValue="" required="" /></td>
                        </tr>
                        <tr>
                            <td><label>Company</label></td>
                            <td><input type="text" name="company" data-label="" defaultValue="" /></td>
                        </tr>
                        <tr>
                            <td><label>Primary Phone</label></td>
                            <td><input type="text" name="phone" data-label="" defaultValue="" /></td>
                        </tr>
                        <tr>
                            <td><label>Primary Email</label></td>
                            <td><input type="email" name="email" data-label="" defaultValue="" /></td>
                        </tr>

                        <tr>
                            <td><label>Description</label></td>
                            <td><textarea name="description"></textarea></td>
                        </tr>
                        <tr>
                            <td><label>File </label></td>
                            <td><input type="file" name="file_3_1" /></td>
                        </tr>
                    </tbody>
                </table>
                <Button
                type="submit"
              variant="danger"
              className="login-submit-button submit-form"
            >
              SEND MAIL
            </Button>
            </form>

        );
    }
}

export default SubmitForm;
